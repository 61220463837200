import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button, Progress
} from "@material-tailwind/react";

import {
    XMarkIcon,
    CheckIcon,
    PlusIcon
} from "@heroicons/react/24/solid";

interface UtilizationProps {
  creditUtilization: number;
  creditLimit: number;
  unixTime: number;
}
export function Utilization({ creditUtilization, creditLimit, unixTime }: UtilizationProps) {
    return (
        <div className="flex space-x-8 py-3 mr-5">
            <div className="w-full">
                <div className="mb-2 flex items-center justify-between gap-4">
                    <Typography color="blue-gray" variant="small">
                        { creditUtilization } of { creditLimit } credits utilized
                    </Typography>
                    <Typography color="blue-gray" variant="small">
                        {creditLimit-creditUtilization} credits remaining until {new Date(unixTime * 1000).toLocaleDateString('en-US')}
                    </Typography>
                </div>
                <Progress color="blue" className="!h-11" value={creditUtilization/creditLimit*100} variant="gradient" />
            </div>
        </div>
    )
}
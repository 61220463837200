import {
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import {
  BuildingLibraryIcon,
  HomeIcon,
  ChatBubbleBottomCenterTextIcon,
  ArrowTopRightOnSquareIcon
} from "@heroicons/react/24/outline";
import {
  HomeIcon as HomeIconFilled,
  BuildingLibraryIcon as BuildingLibraryIconFilled
} from "@heroicons/react/24/solid";
import { useEffect } from "react";

function doThis (w: any, d: any, i: any, s: any)
        {
          function l()
          {
            if (!d.getElementById(i))
            {
              var f = d.getElementsByTagName(s)[0], e = d.createElement(s);
              (e as any).type = "text/javascript";
              (e as any).async = !0;
              (e as any).src = "https://canny.io/sdk.js";
              (f as any).parentNode.insertBefore(e, f);
            }
          }
          if ("function" != typeof (w as any).Canny)
          {
            var c = function () { (c as any).q.push(arguments) };
            (c as any).q = [];
            (w as any).Canny = c
            "complete" === d.readyState ? l() : (w as any).attachEvent ? (w as any).attachEvent("onload", l) : w.addEventListener("load", l, !1)
          }
        }
export function Sidebar({ onComponentSelect, activeComponent }: any) {

   useEffect(() => { 
    doThis(window, document, 'canny-jssdk', 'script');
    console.log("canny");
  } , []);
  return (
    <div className="h-[calc(100vh-2rem)] w-full max-w-[15rem] p-4 mr-4">
      <div className="mb-2 flex items-center gap-4 hidden md:block">
        <img src="/logo.svg" alt="Video Insights" />
      </div>
      <List className="text-black">
        <ListItem onClick={() => onComponentSelect('home')} className={activeComponent === 'home' ? 'font-bold text-sm' : 'text-sm'}>
          <ListItemPrefix className="pr-3">
            {activeComponent === 'home' ?
              <HomeIconFilled className="h-5 w-5" /> :
              <HomeIcon className="h-5 w-5" />
            }
          </ListItemPrefix>
          Dashboard
        </ListItem>
        <ListItem onClick={() => onComponentSelect('billing')} className={activeComponent === 'billing' ? 'font-bold text-sm' : 'text-sm'}>
          <ListItemPrefix className="pr-3">
            {activeComponent === 'billing' ?
              <BuildingLibraryIconFilled className="h-5 w-5" /> :
              <BuildingLibraryIcon className="h-5 w-5" />
            }
          </ListItemPrefix>
          Plan & Billing
        </ListItem>
        <hr className="my-2 border-blue-gray-50" />
        <ListItem className="text-sm">
          <a href="https://video-insights.canny.io/feature-requests" target="_blank" rel="noopener noreferrer" className="flex items-center w-full">
            {/* <ListItemPrefix>
              <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
            </ListItemPrefix> */}
            <span className="flex items-center gap-2">Feature Requests<ArrowTopRightOnSquareIcon className="h-4 w-4" /></span>
          </a>
        </ListItem>

      </List>
    </div>
  );
}
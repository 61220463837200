import { FreeTierPricingCard, PlusTierPricingCard, ProTierPricingCard } from "./BillingOptions";
import { Button, Chip, Input } from "@material-tailwind/react";
import { getStripeCustomerPortalSessionUrl } from "../../../services/api-client";

export function InputIcon() {
  return (
    <div className="w-48">
      <Input className="rounded" value="• • • •  • • • •  • • • •  • • • •" disabled icon={<i className="fas fa-heart" />} />
    </div>
  );
}
interface BillingProps {
  currentPlan: string;
  nextBillingDate: number;
  email: string;
}

export function Billing({ currentPlan, nextBillingDate, email }: BillingProps) {
  const handleEditPaymentClick = async () => {
  try {
    const customerPortalUrl = await getStripeCustomerPortalSessionUrl(email);
    if (customerPortalUrl) {
      window.location.href = customerPortalUrl;
    } else {
      console.error("Failed to retrieve customer portal session URL.");
    }
  } catch (error) {
    console.error("Error getting customer portal session:", error);
  }
  }
  const hasStripeAccount = (currentPlan != "Free");
  return (
    <div className="w-full md:w-96 text-left ml-4">
      <h1 className="text-xs font-bold mt-8 md:mt-0 text-gray-600">Plan & Billing</h1>
      <h2 className="text-xl md:text-2xl font-bold">All Plans</h2>
      <div className="px-4 md:px-0">  {/* Added this container div */}
          <div className="flex flex-col space-y-8 md:flex-row md:space-y-0 md:space-x-8 py-8">
              <FreeTierPricingCard currentPlan={currentPlan === "Free"} email={email} hasStripeAccount={hasStripeAccount} />
              <PlusTierPricingCard currentPlan={currentPlan === "Advanced"} upgrade={currentPlan === "Free"} email={email} hasStripeAccount={hasStripeAccount}/>
              <ProTierPricingCard currentPlan={currentPlan === "Professional"} email={email} hasStripeAccount={hasStripeAccount}/>
          </div>
      </div>
            {(currentPlan !== "Free" && currentPlan !== "") && (
                <>
                    <h3 className="text-xl md:text-2xl font-bold">Payment Methods</h3>
                    <h3 className="text-s font-bold text-gray-700 py-2">Credit or debit card</h3>
                    <div className="flex space-x-8 mb-2">
                        <InputIcon />
                        <Button size="md" color="gray" className="rounded-full !mx-4" onClick={handleEditPaymentClick}>Edit</Button>
                    </div>
                    <div className="flex space-x-8">
                        <span className="text-xs font-bold text-gray-600">Next payment on {new Date(nextBillingDate * 1000).toLocaleDateString('en-US')}</span>
                    </div>
                </>
            )}
        </div>)
}
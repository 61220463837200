/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_n9IwSNza0",
    "aws_user_pools_web_client_id": "47j2k3om9hirirgrfqspubfkrj",
    "oauth": {
        "domain": "auth.videoinsights.ai.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "https://openai.videoinsights.io/all",
            "openid",
            "phone"
        ],
        "redirectSignIn": "https://app.videoinsights.ai,https://beta.jettel.de,https://beta.videoinsights.ai",
        "redirectSignOut": "https://app.videoinsights.ai",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;

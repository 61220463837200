import { Auth } from "aws-amplify";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const isDevEnv = Boolean(
    window.location.hostname.includes("app-dev.videoinsights.ai")
);

const isProdEnv = Boolean(
  !isDevEnv && !isLocalhost
)

async function getAuthToken() {
    try {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
    } catch (error) {
        console.error('Error getting auth token:', error);
    }
}
export async function get(path: string) {
    const token = await getAuthToken();
    if (!token) {
        console.error('No auth token found');
        return;
    }
    const apiUrl = `https://${isProdEnv ? "action" : "backend-api-dev"}.videoinsights.ai${path}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        return response.json();
    } else {
        console.error('API call failed:', response.statusText);
    }
}


export async function getStripeCustomerPortalSessionUrl(email: string) {
    const apiUrl = `https://q6v94mcuwi.execute-api.us-east-1.amazonaws.com/prod/stripe/customerPortalSession?email=${encodeURIComponent(email)}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    console.log(`Request to get customer portal url: ${response}`);
    if (response.ok) {
        const responseData = await response.json();
        return responseData.dashboardSessionUrl;
    } else {
        console.error('API call failed:', response.statusText);
    }
};
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";

  import {
    XMarkIcon,
    CheckIcon, 
    PlusIcon
} from "@heroicons/react/24/solid";
  
import { getStripeCustomerPortalSessionUrl } from "../../../services/api-client";
   
  interface PricingCardProps {
    currentPlan: boolean;
    upgrade?: boolean;
    email: string;
    hasStripeAccount: boolean;
  }
  const getCustomerPortalUrl = async (email: string) => {
    try {
      const customerPortalUrl = await getStripeCustomerPortalSessionUrl(email);
      if (customerPortalUrl) {
        window.location.href = customerPortalUrl;
      } else {
        console.error("Failed to retrieve customer portal session URL.");
      }
    } catch (error) {
      console.error("Error getting customer portal session:", error);
    }
  }
  const getPaymentLink = async (plan: string, email: string) => {
    const advancedPlanURL = "https://buy.stripe.com/8wMaEO9yr3Fo6763cd";
    const professionalPlanURL = "https://buy.stripe.com/7sI8wGbGzfo63YY144";
    const url = plan === "Advanced" ? advancedPlanURL : professionalPlanURL;
    const urlWithParams = `${url}?prefilled_email=${encodeURIComponent(email)}`;
    window.location.href = urlWithParams;
  }

export function FreeTierPricingCard({ currentPlan, email }: PricingCardProps) {
  const getFreeUserCustomerPortalUrl = async () => {
    return getCustomerPortalUrl(email)
  }
    return (
        <Card color="gray" variant="gradient" className={"min-w-[15rem] md:min-w-[20rem] w-full max-w-[30rem] p-8 " + (currentPlan ? "border-primary-teal border-4" : "")}>
       <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
        >
          <Typography
            variant="small"
            color="white"
            className="font-normal uppercase"
          >
            free
          </Typography>
          <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal"
          >
            <span className="mt-2 text-4xl">$</span>0{" "}
            <span className="self-end text-4xl">/mo</span>
          </Typography>
        </CardHeader>
        <CardBody className="p-0">
          <ul className="flex flex-col gap-4">
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <XMarkIcon className="h-3 w-3"  />
              </span>
              <Typography className="font-normal">Up to 200 credits per month</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <XMarkIcon className="h-3 w-3" />
              </span>
              <Typography className="font-normal">Limited to 1 hour videos</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <XMarkIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">YouTube Transcripts/Metadata</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <XMarkIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Uploaded Audio/Video</Typography>
            </li>
          </ul>
        </CardBody>
        <CardFooter className="mt-12 p-0">
          {currentPlan ? <h2 className="text-primary-teal text-center font-bold text-md">CURRENT PLAN</h2> :
              <Button
              size="lg"
                color="gray"
                onClick={getFreeUserCustomerPortalUrl}
            className="rounded-full hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
            ripple={false}
            fullWidth={true}
          >
            Downgrade
          </Button>
          }
        </CardFooter>
      </Card>
    );
  }

export function PlusTierPricingCard({ currentPlan, upgrade, email }: PricingCardProps) {
  const getAdvancedPaymentLink = async () => {
    return getPaymentLink("Advanced", email)
  }
  const getAdvancedCustomerPortalUrl = async () => {
    return getCustomerPortalUrl(email)
  }
  return (
    <Card className={`bg-gradient-to-tr from-secondary-blue to-blue-four min-w-[15rem] md:min-w-[20rem] w-full max-w-[30rem] p-8 ${currentPlan ? 'border-primary-teal border-4' : ''}`} >
      <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
        >
          <Typography
            variant="small"
            color="white"
            className="font-bold uppercase"
          >
            plus
          </Typography>
          <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal"
          >
            <span className="mt-2 text-4xl">$</span>10{" "}
            <span className="self-end text-4xl">/mo</span>
          </Typography>
        </CardHeader>
        <CardBody className="p-0 text-white">
        <ul className="flex flex-col gap-4">
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Up to 1200 credits monthly</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Up to 2 hour videos</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">YouTube Transcripts/Metadata</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <CheckIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Uploaded Audio/Video</Typography>
            </li>
          </ul>
        </CardBody>
        <CardFooter className="mt-12 p-0">
          {currentPlan ? <h2 className="text-primary-teal text-center font-bold text-md">CURRENT PLAN</h2> : 
            <Button
            size="lg"
            className="bg-gradient-to-r from-blue-four to-blue-three rounded-full hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
            ripple={false}
            fullWidth={true}
            onClick={upgrade ? getAdvancedPaymentLink : getAdvancedCustomerPortalUrl}
          >
            { upgrade ? "Upgrade" : "Downgrade" }
          </Button>
          }
        </CardFooter>
      </Card>
    );
  }

export function ProTierPricingCard({ currentPlan, email, hasStripeAccount }: PricingCardProps) {
  const getProPaymentLink = async () => {
    return getPaymentLink("Professional", email)
  }
  const getProCustomerPortalUrl = async () => {
    return getCustomerPortalUrl(email)
  }
  return (
    <Card className={`bg-gradient-to-tr from-primary-blue from-20% to-blue-three to-80% min-w-[15rem] md:min-w-[20rem] w-full max-w-[30rem] p-8 ${currentPlan ? 'border-primary-teal border-4' : ''}`} >
       <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
        >
          <Typography
            variant="small"
            color="white"
            className="font-bold uppercase"
          >
            pro
          </Typography>
          <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal"
          >
            <span className="mt-2 text-4xl">$</span>20{" "}
            <span className="self-end text-4xl">/mo</span>
          </Typography>
        </CardHeader>
        <CardBody className="p-0 text-white">
        <ul className="flex flex-col gap-4">
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <PlusIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Up to 2700 credits per month</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <PlusIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Up to 4 hour videos</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <PlusIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">YouTube Transcripts/Metadata</Typography>
            </li>
            <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-white/20 p-1">
                <PlusIcon className="h-3 w-3"/>
              </span>
              <Typography className="font-normal">Uploaded Audio/Video</Typography>
            </li>
          </ul>
        </CardBody>
        <CardFooter className="mt-12 p-0">
          {currentPlan ? <h2 className="text-primary-teal text-center font-bold text-md">CURRENT PLAN</h2> : 
          
          <Button
            size="lg"
            className="bg-gradient-to-r from-blue-four to-blue-three rounded-full hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
            ripple={false}
            fullWidth={true}
            onClick={hasStripeAccount ? getProCustomerPortalUrl : getProPaymentLink }
          >
            Upgrade
          </Button>
          }
        </CardFooter>
      </Card>
    );
  }
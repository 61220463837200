// import { ActivitiesTimeline } from "./ActivitiesTimeline";
import { Utilization } from "./Utilization";

interface DashboardProps {
  creditUtilization: number;
  creditLimit: number;
  resetDate: number;
  // activities: any[];
}

export function Dashboard({ creditUtilization, creditLimit, resetDate}: DashboardProps) {
    return (
      <div>
      <div className="w-100 text-left ml-4">
        <h1 className="text-xs font-bold mt-8 text-gray-600" >Dashboard</h1>
        <h2  className="text-2xl font-bold">Current Utilization</h2>
          <Utilization creditUtilization={creditUtilization} creditLimit={creditLimit}  unixTime={resetDate} />
          {/* <ActivitiesTimeline activities={activities} endTime={resetDate} /> */}
      </div>
      </div>
    )
  }
import { Sidebar } from './components/Sidebar/Sidebar';
import { useState, useEffect, SetStateAction } from 'react';
import { ProfileMenu } from './components/ProfileMenu';
import { Dashboard } from './components/Sidebar/Dashboard/Dashboard';
import { Billing } from './components/Sidebar/Billing/Billing';
import { Auth } from 'aws-amplify';
import {
  Bars2Icon
} from "@heroicons/react/24/outline";
import { components, formFields } from './components/AuthCustomization';
import { withAuthenticator, WithAuthenticatorProps, Authenticator, } from '@aws-amplify/ui-react';
import "./index.css";
import '@aws-amplify/ui-react/styles.css';
import "./more.css";
import { getUserInfo } from './services/user-info-service';


export default function App() {
  const [activeComponent, setActiveComponent] = useState('home');
  const [email, setEmail] = useState("");
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const handleComponentSelect = (activeComponent: SetStateAction<string>) => {
      setActiveComponent(activeComponent);
      setSidebarOpen(false);  // Close the sidebar when an item is selected
  };
  const [creditUtilization, setCreditUtilization] = useState(0);
  const [creditLimit, setCreditLimit] = useState(0);
  const [resetDate, setResetDate] = useState(Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() / 1000));
  // const [activities, setActivity] = useState<any[]>([]);
  const [nextBillingDate, setnextBillingDate] = useState(0);
  const [currentPlan, setCurrentPlan] = useState("");

  function doThis (w: any, d: any, i: any, s: any)
        {
          function l()
          {
            if (!d.getElementById(i))
            {
              var f = d.getElementsByTagName(s)[0], e = d.createElement(s);
              (e as any).type = "text/javascript";
              (e as any).async = !0;
              (e as any).src = "https://canny.io/sdk.js";
              (f as any).parentNode.insertBefore(e, f);
            }
          }
          if ("function" != typeof (w as any).Canny)
          {
            var c = function () { (c as any).q.push(arguments) };
            (c as any).q = [];
            (w as any).Canny = c
            "complete" === d.readyState ? l() : (w as any).attachEvent ? (w as any).attachEvent("onload", l) : w.addEventListener("load", l, !1)
          }
        }
  useEffect(() => {
    async function fetchData() {
      try {
        const userInfo = await getUserInfo();
        if (!userInfo) {
          console.error('No data found');
          return;
        }
        console.log(userInfo);
        setCurrentPlan(userInfo.current_plan);
        setnextBillingDate(userInfo.token_reset_timestamp);
        setCreditUtilization(userInfo.vi_tokens_used);
        setCreditLimit(userInfo.vi_tokens_used+userInfo.vi_tokens_unused);
        setResetDate(userInfo.token_reset_timestamp);
        // setActivity([]);
        setEmail(userInfo.email);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => { 
    doThis(window, document, 'canny-jssdk', 'script');
    console.log("canny");
  } , []);
  return (
    <Authenticator formFields={formFields} components={components}>
      {({ user }) => (
        <div className="flex flex-col md:flex-row h-screen">
          <div className={`bg-white p-4 z-50 ${isSidebarOpen ? 'h-auto' : 'h-16'} md:h-screen md:w-64 md:block transition-all duration-300`}>
            <div className="flex justify-between items-center md:hidden">
              <button onClick={() => setSidebarOpen(!isSidebarOpen)}>
                <Bars2Icon className="h-5 w-5" />
              </button>
              <img src="/logo.svg" alt="Logo" />
              <div className="relative">
                <ProfileMenu email={email}></ProfileMenu>  {/* ProfileMenu for small screens */}
              </div>
            </div>
            <div className={`${isSidebarOpen ? '' : 'hidden'} md:block`}>
              <Sidebar onComponentSelect={handleComponentSelect} activeComponent={activeComponent} />
            </div>
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto md:pt-4">
            {activeComponent === 'home' && <Dashboard creditUtilization={creditUtilization} creditLimit={creditLimit} resetDate={resetDate}/>}
            {activeComponent === 'billing' && <Billing currentPlan={currentPlan} nextBillingDate={nextBillingDate} email={email} />}
          </div>
          <div className="relative md:absolute top-0 right-0 p-4 hidden md:block">
            <ProfileMenu email={email}></ProfileMenu>  {/* ProfileMenu for medium and larger screens */}
          </div>
        </div>
      )}
    </Authenticator>
  )
}
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Avatar,
    Typography,
} from "@material-tailwind/react";
import { Auth } from 'aws-amplify';
interface ProfileMenuProps {
  email?: string;
}

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}
export function ProfileMenu({email}: ProfileMenuProps) {
    return (
      <Menu placement="bottom-end">
        <MenuHandler>
          <Avatar
            variant="circular"
            className="cursor-pointer"
            src="/profile.jpeg"
            />
        </MenuHandler>
        <MenuList>
        <MenuItem disabled={true} className="flex items-center gap-2">
            <Typography variant="small" className="font-normal">
              {email || "Loading..."}
            </Typography>
          </MenuItem>
          {/* <hr className="my-2 border-blue-gray-50" />
          <MenuItem className="flex items-center gap-2">
            <Typography variant="small" className="font-normal">
              Account
            </Typography>
          </MenuItem>
          <MenuItem className="flex items-center gap-2">
            <Typography variant="small" className="font-normal">
              Help
            </Typography>
          </MenuItem> */}
          <hr className="my-2 border-blue-gray-50" />
          <MenuItem onClick={signOut} className="flex items-center gap-2 ">
            <Typography variant="small" className="font-normal">
              Sign Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }